import axios from 'axios';
import dayjs from 'dayjs';
import {
    buildUrlParams, createDataHash,
    getDefaultDataGetters,
    getDefaultDataMutations,
    getDefaultDataState,
} from '@/utils/store-helpers';
import { getApiUrl } from '@/utils/api';

const state = {
    ...getDefaultDataState({ hasPagination: false }),
    countData: {
        low: 0,
        medium: 0,
        high: 0,
        veryHigh: 0,
    },
    isLoading: true,
};

const getters = {
    ...getDefaultDataGetters({ hasPagination: false }),

    getCountData(state) {
        return state.countData;
    },
    isLoading(state) {
        return state.isLoading;
    },
};

const mutations = {
    ...getDefaultDataMutations({ hasPagination: false }),

    setCountData(state, payload) {
        state.countData = {
            low: payload.low_risk || 0,
            medium: payload.medium_risk || 0,
            high: payload.high_risk || 0,
            veryHigh: payload.critical_risk || 0,
        };
    },

    setIsLoading(state, payload) {
        state.isLoading = payload;
    },
};

const actions = {
    async loadData({
        state, commit, rootState,
    }) {
        const { dataFilters } = rootState;
        const { startDate, endDate } = dataFilters;

        const from = dayjs(startDate).format('YYYY-MM-DDTHH:mm:00');
        const to = dayjs(endDate).format('YYYY-MM-DDTHH:mm:00');

        const params = buildUrlParams({
            additional: {
                from,
                to,
            },
        });

        const path = `hsafety/risk_score/count/${from}/to/${to}/`;

        const dataHash = createDataHash(path, params);

        if (state.dataHash === dataHash) {
            return Promise.resolve(true);
        }

        commit('setDataHash', { dataHash });
        commit('setStatus', { status: 'loading' });
        commit('setIsLoading', true);

        try {
            const response = await axios(
                {
                    url: getApiUrl({ path }),
                },
            );

            commit('setCountData', response.data);
            commit('setStatus', { status: 'success' });
        } catch (e) {
            console.error('Error loading data:', e);
            commit('setStatus', { status: 'error' });
        }

        commit('setIsLoading', false);

        return Promise.resolve(true);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
