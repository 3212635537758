<template>
    <div class="app-toolbar">
        <div class="app-toolbar__section">
            <template v-if="displayNavToggle">
                <button type="button" class="app-toolbar__menu" @click="toggleNav()">
                    <b-icon icon="menu" />
                </button>
            </template>
            <h2 v-html="pageTitle" />
        </div>
        <div class="app-toolbar__section" v-if="displayUser">
            <b-dropdown
                class="app-toolbar__dropdown"
                position="is-bottom-left"
                trap-focus
                :mobile-modal="false"
            >
                <template v-slot:trigger="{ active }">
                    <button type="button" class="app-toolbar__dropdown__cta">
                        <span class="app-toolbar__dropdown__name" v-text="userFullName" />
                        <span class="app-toolbar__dropdown__avatar" v-text="userInitials" />
                        <b-icon :icon="active ? 'chevron-up' : 'chevron-down'" />
                    </button>
                </template>
                <template v-slot:default>
                    <b-dropdown-item class="app-toolbar__dropdown__item" role="listitem" has-link>
                        <router-link to="/dashboard/account">Profile</router-link>
                    </b-dropdown-item>
                    <b-dropdown-item role="listitem" @click="logout">Logout</b-dropdown-item>
                </template>
            </b-dropdown>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
    props: {
        displayUser: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        ...mapGetters('user', ['userInitials', 'userFullName']),

        displayNavToggle() {
            return this.$bus.isMobile;
        },

        pageTitle() {
            return this.$route.meta && this.$route.meta.pageTitle
                ? this.$route.meta.pageTitle
                : this.$route.name;
        },
    },

    methods: {
        ...mapMutations('dashboard/layout', ['toggleNav']),

        logout() {
            this.$store.dispatch('auth/logout')
                .then(() => this.$router.push('/login'));
        },
    },
};
</script>

<style lang="scss" scoped>
    .app-toolbar {
        flex: 1;
        display: flex;
        justify-content: space-between;

        &__menu {
            @include button-reset;
            width: 24px;
            height: 24px;
            color: $white;
            margin-right: 15px;
        }

        &__section {
            flex: 0 0 auto;
            display: flex;
            align-items: center;

            h2 {
                color: $white;
                margin: 0;
                @include mobile {
                    font-size: 18px;
                }
                @include tablet {
                    font-size: 20px;
                }
                @include desktop {
                    font-size: 24px;
                }
            }
        }

        &__dropdown {
            &__cta {
                color: $white;
                display: inline-flex;
                background: transparent;
                border: 0;
                align-items: center;
                cursor: pointer;
                padding: 0;
                margin: 0;
                transition: all $speed $easing;
                font-weight: $weight-normal;
                @include mobile {
                    height: 30px;
                    font-size: 14px;
                }
                @include tablet {
                    height: 40px;
                    font-size: 16px;
                }
                @include desktop {
                    height: 50px;
                }

                &:hover {
                    color: rgba($white, .8);
                }

                &,
                &:active,
                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }

            &__name {
                margin-right: 10px;
                @include mobile {
                    display: none;
                }
                @include tablet {
                    font-size: 14px;
                }
                @include desktop {
                    font-size: 16px;
                }
            }

            &__avatar {
                margin-right: 10px;
                display: block;
                border-radius: $radius-rounded;
                background: $grey;
                color: $primary;
                font-size: 24px;
                letter-spacing: 0.01em;
                width: 30px;
                height: 30px;
                line-height: 30px;
                font-size: 18px;
                @include desktop {
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    font-size: 22px;
                }
            }
        }
    }
</style>
