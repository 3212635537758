<template>
    <div class="media-video">
        <b-loading
            :active="isLoading"
            :can-cancel="false"
            :is-full-page="false"
        />
        <template v-if="url">
            <template v-if="isError">
                <div class="media-video__error">
                    <div class="content">
                        <p>{{ message }}</p>
                    </div>
                </div>
            </template>
            <template v-else>
                <div ref="container" class="media-video__container">
                    <div
                        ref="videoContainer"
                        class="media-video__video-container"
                    >
                        <div
                            ref="scene"
                            :style="sceneStyles"
                            class="media-video__scene"
                        >
                            <div class="media-video__wrapper">
                                <video
                                    v-if="isPlaying"
                                    :src="url"
                                    :poster="poster"
                                    controls
                                    autoplay
                                    :loop="false"
                                    @error="onVideoError"
                                    @play="onVideoPlay"
                                    :ref="`video-${url}`"
                                    @loadeddata="onVideoLoad"
                                />
                                <div
                                    class="media-video__preview"
                                    @click="play()"
                                    v-else
                                >
                                    <img
                                        v-if="poster"
                                        :src="poster"
                                        alt=""
                                        class="media-video__preview-image"
                                        ref="image"
                                        @load="onImageLoad"
                                    />
                                    <span v-else class="media-video__preview-bg" />
                                    <button type="button" class="media-video__preview-cta">
                                        <b-icon icon="play" />
                                    </button>
                                </div>
                                <media-image-h-s-canvas
                                    v-if="isHS"
                                    class="media-video__canvas"
                                    :is-visible="!isLoading"
                                    :width="imageSize.width"
                                    :height="imageSize.height"
                                    :detections="detections"
                                    :camera="camera"
                                    :scale="scale"
                                    :translate="translate"
                                    :displayRoi="displayRoi"
                                    :style="{
                                        width: `${this.imageSize.width}px`,
                                        height: `${this.imageSize.height}px`,
                                    }"
                                    :displayHeatmap="displayHeatmap"
                                    :heatmapImage="heatmapImage"
                                    :imageConfig="imageConfig"
                                />
                                <media-image-canvas
                                    v-if="!isHS"
                                    class="media-video__canvas"
                                    :is-visible="!isLoading"
                                    :width="imageSize.width"
                                    :height="imageSize.height"
                                    :detections="detections"
                                    :image-type="imageType"
                                    :scale="scale"
                                    :translate="translate"
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        class="media-video__timeline"
                        :style="{
                            height: `${this.imageSize.height}px`,
                            width: `15%`,
                        }"
                    >
                        <ul
                            v-if="timestamps.length > 0"
                        >
                            <div class="media-video__timeline_title">List of observation times:</div>
                            <li
                                v-for="timestamp in timestamps"
                                :key="timestamp.frame_number"
                                @click="seekToTimestamp(timestamp.start)"
                            >
                                <span class="media-video__timeline_bullet">•</span>
                                <span class="media-video__timeline_timestamp">{{ `${formatTime(timestamp.start)}-${formatTime(timestamp.end)}` }}</span>
                                <span class="media-video__timeline_violation">{{ ` ${timestamp.violationText}` }}</span>
                                <span
                                    :class="getRiskScoreClass(timestamp.riskLevel)"
                                >
                                    {{ ` [${timestamp.riskLevel}]` }}
                                </span>
                            </li>
                        </ul>
                        <ul v-else-if="isPlaying && timestamps.length === 0">
                            <li>No detections found</li>
                        </ul>
                        <ul v-else>
                            <li>Play video to see observation timestamps</li>
                        </ul>
                    </div>
                </div>
            </template>
        </template>
        <template v-else>
            <div class="media-video__error">
                <div class="content">
                    <p>{{ message }}</p>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import MediaImageCanvas from '@/components/local/data/MediaImageCanvas';
import MediaImageHSCanvas from '@/components/local/data/MediaImageHSCanvas';
import { binarySearchClosestSmallerNumber } from '@/utils/number';
import { getTansformationConfig } from '@/utils/canvas';
import {
    STATUS_LOADING,
    STATUS_PENDING,
    STATUS_READY,
    MAX_INTERPOLATION_INTERVAL,
    BOX_FADE_OUT_TIME,
} from '@/utils/constants';
import { getPPEDetections, isViolation } from '@/utils/hs/helpers';
import { getRiskScoreClass } from '../../../utils/hs/helpers';

export default {
    components: { MediaImageHSCanvas, MediaImageCanvas },
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        url: {
            type: String,
            default: '',
        },
        poster: {
            type: String,
            default: '',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        camera: {
            type: [String, Object],
            default: null,
        },
        tracks: {
            type: Array,
            default: null,
        },
        proximityTracks: {
            type: Array,
            default: null,
        },
        displayRoi: {
            type: Boolean,
            default: true,
        },
        displayTags: {
            type: Boolean,
            default: false,
        },
        displayHeatmap: {
            type: Boolean,
            default: true,
        },
        heatmapImage: {
            type: HTMLImageElement,
            required: false,
            default: null,
        },
    },

    mounted() {
        this.$bus.$on('resize', this.resize);
        this.$once('hook:beforeDestroy', () => {
            this.$bus.$off('resize', this.resize);
        });
    },

    data() {
        return {
            imageStatus: STATUS_PENDING,
            isPlaying: false,
            isError: false,
            message: 'No video provided',
            isHS: true,
            containerSize: null,
            videoContainerSize: null,
            imageType: 'vehicle',
            imageSize: {
                width: 0,
                height: 0,
            },
            previousFrameNumber: 0,
            scale: 1,
            translate: [0, 0],
            detections: {
                results_generic: [],
                results_proximity: [],
            },
            lastMediaTime: 0,
            lastFrameNumber: 0,
            fps: 0,
            fpsRounder: [],
            video: null,
            timestamps: [],
            thumbnail: null,
        };
    },

    watch: {
        url: {
            handler() {
                this.imageStatus = STATUS_PENDING;
                this.isPlaying = false;
                this.clearObjectBBs();
                this.clearObjectProximity();
                this.resetFPS();
            },
            immediate: true,
        },
        isReady() {
            this.resize();
        },
        displayTags: {
            handler(val) {
                if (!val) {
                    this.clearAnimationCanvas();
                    this.stopVideoAnimation();
                } else {
                    this.startVideoAnimation();
                }
            },
            immediate: true,
        },
    },

    computed: {
        isLoading() {
            return this.imageStatus === STATUS_LOADING || this.loading;
        },
        isReady() {
            return this.imageStatus === STATUS_READY;
        },
        filteredTracks() {
            // Filter the tracks that are inside the safety zone
            const filteredTracks = [];
            this.tracks.forEach((_, key) => {
                filteredTracks[key] = this.tracks[key]; // .filter((item) => isViolation(item, { zoneList: item.rois }));
                if (filteredTracks[key].length === 0) {
                    delete filteredTracks[key];
                }
            });
            return filteredTracks;
        },
        sceneStyles() {
            return this.updateSceneStyles();
        },
        imageConfig() {
            return {
                image: this.thumbnail,
            };
        },
    },

    methods: {
        onVideoLoad() {
            this.video = this.$refs[`video-${this.url}`];
        },

        onImageLoad() {
            function getCanvasFromImage(image) {
                const canvas = document.createElement('canvas');
                canvas.width = image.width * 2;
                canvas.height = image.height * 2;
                const ctx = canvas.getContext('2d');
                ctx.filter = 'blur(5px)';
                ctx.drawImage(image, 0, 0);
                return canvas;
            }

            const { image } = this.$refs;
            this.thumbnail = getCanvasFromImage(image);
            this.imageStatus = STATUS_READY;
        },

        onVideoError() {
            const match = this.url.match(/Expires=(\d+)/);
            if (match) {
                if (this.$date().unix() > Number(match[1])) {
                    this.message = 'The link to this video has expired after 60 minutes of inactivity. Please refresh your browser window to reload the video.';
                    return;
                }
            }
            this.isError = true;
            this.message = 'This video has been deleted as your data retention policy is set to 30 days. To review or modify this policy please contact us.';
        },

        clearAnimationCanvas() {
            this.clearObjectBBs();
            this.clearObjectProximity();
        },

        startVideoAnimation() {
            this.video.requestVideoFrameCallback(this.ticker);
            requestAnimationFrame(this.updateCanvas.bind(this, null));
        },

        stopVideoAnimation() {
            this.video.cancelVideoFrameCallback(this.ticker);
            cancelAnimationFrame(this.updateCanvas.bind(this, null));
        },

        onVideoPlay() {
            if (this.video.currentTime < 1) {
                this.clearAnimationCanvas();
            }
            this.startVideoAnimation();
        },

        updateSceneStyles() {
            const { image } = this.$refs;
            if (this.zoomed || !this.videoContainerSize || !image) {
                return {};
            }

            let width;
            let height;

            if (image && image.naturalWidth) {
                const ratio = image.naturalWidth / image.naturalHeight;

                if (ratio > this.videoContainerSize.ratio) {
                    ({ width } = this.videoContainerSize);
                    height = parseInt(this.videoContainerSize.width / ratio, 10);
                } else {
                    width = parseInt(this.videoContainerSize.height * ratio, 10);
                    ({ height } = this.videoContainerSize);
                }

                this.imageSize = {
                    width,
                    height,
                };

                const { scale } = getTansformationConfig({
                    clientWidth: width,
                    clientHeight: height,
                    mediaWidth: image.naturalWidth,
                    mediaHeight: image.naturalHeight,
                });

                this.scale = scale;
            }

            return {
                width: `${width}px`,
                height: `${height}px`,
            };
        },

        updateBbs(previousFrameNumber, frameNumber, lerp) {
            if (previousFrameNumber !== frameNumber) {
                const frameDetections = this.tracks.map((track) => {
                    const frameNums = track?.map((f) => f.frame_number);
                    const lookupFrameNumber = binarySearchClosestSmallerNumber(
                        frameNums,
                        frameNumber,
                    );
                    const firstFrame = track?.find(
                        (f) => f.frame_number === lookupFrameNumber,
                    );
                    if (!firstFrame) {
                        return undefined;
                    }
                    let combinedFrame = firstFrame;
                    const firstFrameIndex = track?.findIndex(
                        (f) => f.frame_number === lookupFrameNumber,
                    );
                    const nextFrame = track[firstFrameIndex + 1];
                    const maxFrameDifference = 5;
                    if (nextFrame && (nextFrame.frame_number - frameNumber) < maxFrameDifference) {
                        const denominator = nextFrame.frame_number - firstFrame.frame_number;
                        let interp = 0;

                        if (denominator > 0) {
                            interp = (frameNumber - firstFrame.frame_number) / denominator;
                        }

                        if ((nextFrame.frame_number / this.fps - firstFrame.frame_number / this.fps)
                            > MAX_INTERPOLATION_INTERVAL / 1000) {
                            interp = 0;
                        }

                        combinedFrame = {
                            ...firstFrame,
                            bb: {
                                b: lerp(
                                    firstFrame.bb.b,
                                    nextFrame.bb.b,
                                    interp,
                                ),
                                l: lerp(
                                    firstFrame.bb.l,
                                    nextFrame.bb.l,
                                    interp,
                                ),
                                r: lerp(
                                    firstFrame.bb.r,
                                    nextFrame.bb.r,
                                    interp,
                                ),
                                t: lerp(
                                    firstFrame.bb.t,
                                    nextFrame.bb.t,
                                    interp,
                                ),
                            },
                        };
                    } else {
                        const opacity = 1 - (this.video.currentTime - firstFrame.frame_number / this.fps)
                            / (BOX_FADE_OUT_TIME / 1000);
                        this.$set(combinedFrame, 'opacity', Math.min(Math.max(opacity, 0), 1));
                    }

                    combinedFrame.line = {
                        points: track
                            .filter((_, i) => i <= firstFrameIndex)
                            .map((trackFrame, i) => [
                                (trackFrame.bb.l + trackFrame.bb.r) / 2,
                                trackFrame.bb.b,
                                ...(i === firstFrameIndex
                                    ? [
                                        (combinedFrame.bb.l
                                            + combinedFrame.bb.r)
                                            / 2,
                                        combinedFrame.bb.b,
                                    ]
                                    : []),
                            ])
                            .flat(),
                    };

                    return combinedFrame;
                }).filter((d) => d !== undefined && d !== null && !(d.object_class === 'Car' && d.speed < 3));

                if (frameDetections.length !== 0) {
                    this.detections.results_generic = frameDetections.map(
                        (d) => ({
                            ...d,
                            isViolation: isViolation(d, { bb: d.bb }),
                        }),
                    );
                } else {
                    this.clearObjectBBs();
                    this.clearObjectProximity();
                }
            }
        },

        updateProximity(previousFrameNumber, frameNumber) {
            if (previousFrameNumber !== frameNumber) {
                const frameNums = this.proximityTracks?.map((frame) => frame.frame_number) ?? [];
                const lookupFrameNumber = binarySearchClosestSmallerNumber(
                    frameNums,
                    frameNumber,
                );
                const firstFrame = this.proximityTracks?.find(
                    (f) => f.frame_number === lookupFrameNumber,
                ) ?? null;
                if (!firstFrame) {
                    return;
                }
                if (frameNumber > lookupFrameNumber) {
                    this.clearObjectProximity();
                    return;
                }

                this.detections.results_proximity = firstFrame.classified_distances;
            }
        },

        updateCanvas(previousFrameNumber) {
            if (!this.displayTags) {
                this.clearAnimationCanvas();
                return;
            }
            const lerp = (a, b, t) => a + (b - a) * t;

            const frameNumber = Math.floor(this.video.currentTime * this.fps);

            this.updateBbs(previousFrameNumber, frameNumber, lerp);
            this.updateProximity(previousFrameNumber, frameNumber);

            requestAnimationFrame(this.updateCanvas.bind(this, frameNumber));
        },

        ticker(now, metadata) {
            const mediaTimeDiff = Math.abs(
                metadata.mediaTime - this.lastMediaTime,
            );
            const frameDiff = Math.abs(
                metadata.presentedFrames - this.lastFrameNumber,
            );
            const diff = mediaTimeDiff / frameDiff;
            if (
                diff
                && diff < 1
                && this.fpsRounder.length < 50
                && this.video.playbackRate === 1
            ) {
                this.fpsRounder.push(diff);
                this.fps = Math.round(1 / this.getAverageFps());
            }
            this.lastMediaTime = metadata.mediaTime;
            this.lastFrameNumber = metadata.presentedFrames;
            this.video.requestVideoFrameCallback(this.ticker);

            if (
                this.timestamps.length === 0
                && this.tracks[0]
                && this.fps !== 0
            ) {
                this.calculateTimestamps();
            }
        },

        getAverageFps() {
            // handle empty array
            if (!this.fpsRounder.length) {
                return 0;
            }

            // check for non-numeric values before adding to the sum
            const sum = this.fpsRounder.reduce((a, b) => (typeof b === 'number' ? a + b : a), 0);

            return sum / this.fpsRounder.length;
        },

        play() {
            this.isPlaying = true;
        },

        clearObjectBBs() {
            this.detections.results_generic = [];
        },

        clearObjectProximity() {
            this.detections.results_proximity = [];
        },

        resetFPS() {
            this.lastMediaTime = 0;
            this.lastFrameNumber = 0;
            this.fps = 0;
            this.fpsRounder = [];
        },

        resize() {
            this.$nextTick(() => {
                const { container, videoContainer } = this.$refs;

                if (videoContainer) {
                    const width = this.$refs.videoContainer.offsetWidth;
                    const height = this.$refs.videoContainer.offsetHeight;
                    this.videoContainerSize = {
                        width,
                        height,
                        ratio: width / height,
                    };
                }

                if (container) {
                    const width = this.$refs.container.offsetWidth;
                    const height = this.$refs.container.offsetHeight;
                    this.containerSize = {
                        width,
                        height,
                        ratio: width / height,
                    };
                }

                if (this.video) {
                    this.imageSize = {
                        width: this.video.clientWidth,
                        height: this.video.clientHeight,
                    };
                    const { translate, scale } = getTansformationConfig({
                        clientWidth: this.video.clientWidth,
                        clientHeight: this.video.clientHeight,
                        mediaWidth: this.video.videoWidth,
                        mediaHeight: this.video.videoHeight,
                    });
                    this.translate = translate;
                    this.scale = scale;
                }
            });
        },

        seekToTimestamp(timestamp) {
            this.video.currentTime = timestamp;
        },

        calculateTimestamps() {
            this.timestamps = Object.keys(this.filteredTracks).map(
                (id) => {
                    const track = this.filteredTracks[id];
                    let highestRiskLevel = 0;
                    const timestamps = track.map(
                        (frame) => {
                            const riskLevel = frame?.risk_level ?? 0;
                            highestRiskLevel = Math.max(highestRiskLevel, riskLevel);
                            return Math.floor(
                                ((frame.frame_number) / this.fps) * 2 * 1000,
                            ) / 1000;
                        },
                    );

                    const startFrame = 0;
                    const endFrame = track.length - 1;
                    const firstFrame = track[startFrame];
                    const objectClass = firstFrame.object_class[0].toUpperCase()
                        + firstFrame.object_class.substring(1).toLowerCase();
                    let violationText = `${objectClass}`;
                    if (firstFrame.object_class === 'Person') {
                        const ppeDetections = getPPEDetections(firstFrame);
                        violationText = `${objectClass} ${ppeDetections.indexOf('vis') !== -1 ? 'No Hi-vis' : ''} ${ppeDetections.indexOf('hat') !== -1 ? 'No Hat' : ''}`;
                    }

                    return {
                        frame_number: startFrame,
                        start: timestamps[startFrame],
                        end: timestamps[endFrame],
                        violationText,
                        riskLevel: highestRiskLevel,
                    };
                },
            ).filter((frame) => frame !== null);
        },

        formatTime(timestamp) {
            const minutes = Math.floor(timestamp / 60);
            const seconds = Math.floor(timestamp) % 60;
            const formattedMinutes = String(minutes).padStart(2, '0');
            const formattedSeconds = String(seconds).padStart(2, '0');
            return `${formattedMinutes}:${formattedSeconds}`;
        },

        getRiskScoreClass(score) {
            return getRiskScoreClass(score);
        },
    },
};
</script>

<style lang="scss" scoped>
.media-video {
    position: relative;
    min-width: 300px;
    min-height: 200px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &__container {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    &__video-container {
        position: relative;
        flex: 1;
        width: auto;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    &__timeline {
        position: relative;
        display: flex;
        background-color: rgba($dark, 0.6);
        align-items: flex-start;
        justify-content: center;
        color: $white;
        padding: 1%;
        overflow-y: scroll;
        overflow-x: hidden;

        li {
            display: flex;
            width: 100%;
            height: 100%;
            font-size: 0.9em;
            cursor: pointer;
            text-align: left;
            justify-content: left;
        }

        span {
            padding: 0 1% 0 0;
        }

        &_title {
            width: 100%;
            justify-content: left;
            text-align: left;
            margin-bottom: 0.5em;
        }

        &_bullet {
            font-weight: 900;
        }

        &_timestamp {
            color: #b0b6c6ff;
            text-decoration: underline;
            width: 50%;
        }

        &_violation {
            color: $white;
            letter-spacing: 1px;
            width: 50%;
        }
    }

    &__scene {
        display: flex;
        position: relative;
        align-items: center;
        width: 100%;
        background-color: rgba($dark, 0.6);
    }

    &__wrapper {
        display: flex;
        position: relative;
        align-items: center;
        overflow: visible;
        width: 100%;
    }

    video {
        position: relative;
        object-fit: contain;
        width: 100%;
        height: 100%;
    }

    &__canvas {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 30;
        pointer-events: none;
    }

    &__error {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        color: $grey-light;
        font-size: 0.9em;
        cursor: default;
    }

    &__preview {
        position: relative;
        width: 100%;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        &-bg {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 20;
            background: rgba($dark, 0.6);
            border-radius: $radius;
        }

        img {
            z-index: 20;
            object-fit: contain;
            width: 100%;
        }

        button {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 30;
            @include button-reset;
            width: 80px;
            height: 80px;
            border-radius: 80px;
            background: rgba($white, 0.4);
            border: 2px solid $white;
            color: $white;
            @include transition-default;

            &:hover {
                background: rgba($primary, 0.2);
                border-color: $primary;
                color: $primary;
            }
        }
    }

    .low-risk {
        color: $low;
    }

    .medium-risk {
        color: $moderate;
    }

    .high-risk {
        color: $critical;
    }
}

::-webkit-scrollbar {
    display: none;
    width: 0;
    background: transparent;
}
</style>
