import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store/store';

import Home from '@/components/views/Home';
import Dashboard from '@/components/views/Dashboard';
import Video from '@/components/views/Video';

import Summary from '@/components/pages/dashboard/Summary';
import Sites from '@/components/pages/dashboard/Sites';
import SitesSingle from '@/components/pages/dashboard/SitesSingle';
import HSExplorer from '@/components/pages/dashboard/hs/HSExplorer';
import DetectionMedia from '@/components/pages/dashboard/DetectionMedia';
// import HSSafetyObservationsExplorer from '@/components/pages/dashboard/hs/HSSafetyObservationsExplorer';
import HSSummary from '@/components/pages/dashboard/hs/HSSummary';
import MauiDataExplorer from '@/components/pages/dashboard/maui63/DataExplorer';
import DataMapExplorer from '@/components/pages/dashboard/maui63/DataMapExplorer';
import VehicleExplorer from '@/components/pages/dashboard/vehicle/VehicleExplorer';
import VehicleOverview from '@/components/pages/dashboard/vehicle/VehicleOverview';
import LiveDashboard from '@/components/pages/dashboard/vehicle/LiveDashboard';
import Account from '@/components/pages/dashboard/Account';
import LiveExplorer from '@/components/pages/dashboard/LiveExplorer';
import Webhooks from '@/components/pages/dashboard/Webhooks';
import WebhooksSingle from '@/components/pages/dashboard/WebhooksSingle';
import Alerts from '@/components/pages/dashboard/Alerts';
import AlertsSingle from '@/components/pages/dashboard/AlertsSingle';
import Hubspot from '@/components/pages/dashboard/Hubspot';
import Enterprise from '@/components/pages/dashboard/Enterprise';

import UserInvite from '@/components/pages/dashboard/user/UserInvite';
import UserInviteForm from '@/components/pages/dashboard/user/UserInviteForm';
import UserManagement from '@/components/pages/dashboard/user/UserManagement';
import UserManagementForm from '@/components/pages/dashboard/user/UserManagementForm';
import UserRegistration from '@/components/pages/dashboard/user/UserRegistration';

import Login from '@/components/pages/home/Login';
import MfaActivation from '@/components/pages/home/MfaActivation';
import MfaAuthentication from '@/components/pages/home/MfaAuthentication';
import { ACCOUNT_TYPE } from '@/utils/constants';
import Marketplace from '@/components/pages/dashboard/marketplace/Marketplace';
import MarketplaceSettings from '@/components/pages/dashboard/marketplace/MarketplaceSettings';
import MarketplaceAppSettings from '@/components/pages/dashboard/marketplace/MarketplaceAppSettings';
// import HSSafetyOverview from '@/components/pages/dashboard/hs/HSSafetyOverview';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return { selector: to.hash };
        }
        if (savedPosition) {
            return savedPosition;
        }

        return { x: 0, y: 0 };
    },
    routes: [
        {
            path: '/video/:id',
            component: Video,
            meta: {
                requiresAuth: true,
                title: 'R/Vision Dashboard',
            },
        },
        {
            path: '/invite/:token',
            component: UserRegistration,
            meta: {
                requiresAuth: false,
                title: 'User Registration',
            },
        },
        {
            path: '/dashboard',
            component: Dashboard,
            meta: {
                requiresAuth: true,
                title: 'R/Vision Dashboard',
            },
            children: [
                {
                    path: '',
                    name: 'Summary',
                    component: Summary,
                },
                {
                    path: 'vehicle-explorer',
                    name: 'Vehicle Explorer',
                    component: VehicleExplorer,
                    meta: {
                        accountType: ACCOUNT_TYPE.bigeyes,
                    },
                },
                {
                    path: 'vehicle-explorer/:id',
                    name: 'Vehicle Media',
                    component: DetectionMedia,
                    meta: {
                        accountType: ACCOUNT_TYPE.bigeyes,
                    },
                },
                {
                    path: 'live-dashboard',
                    name: 'Live Dashboard',
                    component: LiveDashboard,
                    meta: {
                        accountType: ACCOUNT_TYPE.bigeyes,
                    },
                },
                {
                    path: 'data-explorer',
                    name: 'Data Explorer',
                    component: MauiDataExplorer,
                    meta: {
                        accountType: ACCOUNT_TYPE.maui63,
                    },
                },
                {
                    path: 'map-explorer',
                    name: 'Map Explorer',
                    component: DataMapExplorer,
                    meta: {
                        accountType: ACCOUNT_TYPE.maui63,
                    },
                },
                {
                    path: 'live-explorer',
                    name: 'Live Explorer',
                    component: LiveExplorer,
                    meta: {
                        accountType: ACCOUNT_TYPE.bigeyes,
                    },
                },
                {
                    path: 'health-and-safety/explorer',
                    name: 'Health & Safety Explorer',
                    component: HSExplorer,
                    meta: {
                        accountType: ACCOUNT_TYPE.bigeyes,
                    },
                },
                {
                    path: 'health-and-safety/explorer/:id',
                    name: 'Health & Safety Media',
                    component: DetectionMedia,
                    meta: {
                        accountType: ACCOUNT_TYPE.bigeyes,
                    },
                },
                /*
                {
                    path: 'health-and-safety/safety-observations-explorer',
                    name: 'Health & Safety Observations Explorer',
                    component: HSSafetyObservationsExplorer,
                    meta: {
                        accountType: ACCOUNT_TYPE.bigeyes,
                    },
                },
                */
                /*
                {
                    path: 'health-and-safety/safety-observations-overview',
                    name: 'Health & Safety Summary',
                    component: HSSafetyOverview,
                    meta: {
                        accountType: ACCOUNT_TYPE.bigeyes,
                    },
                },
                */
                {
                    path: 'vehicle-observations-overview',
                    name: 'Vehicle Overview',
                    component: VehicleOverview,
                    meta: {
                        accountType: ACCOUNT_TYPE.bigeyes,
                    },
                },
                {
                    path: 'health-and-safety',
                    name: 'Health & Safety Overview',
                    component: HSSummary,
                    meta: {
                        accountType: ACCOUNT_TYPE.bigeyes,
                    },
                },
                {
                    path: 'account',
                    name: 'Account Details',
                    component: Account,
                },
                {
                    path: 'sites',
                    name: 'Sites',
                    component: Sites,
                    meta: {
                        pageTitle: 'Sites',
                    },
                },
                {
                    path: 'sites/:guid',
                    name: 'Site',
                    component: SitesSingle,
                    meta: {
                        pageTitle: 'Sites',
                    },
                },
                {
                    path: 'alerts',
                    name: 'Alerts',
                    component: Alerts,
                    meta: {
                        pageTitle: 'Integrations',
                    },
                },
                {
                    path: 'alerts/:guid',
                    name: 'Alert',
                    component: AlertsSingle,
                    meta: {
                        pageTitle: 'Integrations',
                    },
                },

                {
                    path: 'webhooks',
                    name: 'Webhooks',
                    component: Webhooks,
                    meta: {
                        pageTitle: 'Integrations',
                    },
                },
                {
                    path: 'webhooks/:guid',
                    name: 'Webhook',
                    component: WebhooksSingle,
                    meta: {
                        pageTitle: 'Integrations',
                    },
                },
                {
                    path: 'hubspot',
                    name: 'Hubspot',
                    component: Hubspot,
                    meta: {
                        pageTitle: 'Integrations',
                        accountType: ACCOUNT_TYPE.maui63,
                    },
                },
                {
                    path: 'enterprise',
                    name: 'Enterprise',
                    component: Enterprise,
                    meta: {
                        pageTitle: 'Integrations',
                    },
                },
                {
                    path: 'marketplace',
                    redirect: 'marketplace/apps',
                    name: 'Marketplace Index',
                    meta: {
                        pageTitle: 'R/VISION Marketplace',
                        accountType: ACCOUNT_TYPE.bigeyes,
                    },
                },
                {
                    path: 'marketplace/apps',
                    name: 'Marketplace Apps',
                    component: Marketplace,
                    meta: {
                        pageTitle: 'App Marketplace',
                        accountType: ACCOUNT_TYPE.bigeyes,
                    },
                },
                {
                    path: 'marketplace/settings',
                    name: 'Marketplace Settings',
                    component: MarketplaceSettings,
                    meta: {
                        pageTitle: 'App Marketplace',
                        accountType: ACCOUNT_TYPE.bigeyes,
                    },
                },
                {
                    path: 'marketplace/apps/:app/settings',
                    name: 'Marketplace App Settings',
                    component: MarketplaceAppSettings,
                    meta: {
                        pageTitle: 'Marketplace App Settings',
                        accountType: ACCOUNT_TYPE.bigeyes,
                    },
                },
                {
                    path: 'user-invites',
                    name: 'User Invites',
                    component: UserInvite,
                    meta: {
                        pageTitle: 'User Invites',
                    },
                },
                {
                    path: 'user-invites/:guid',
                    name: 'User Invite Details',
                    component: UserInviteForm,
                    meta: {
                        pageTitle: 'User Invites',
                    },
                },
                {
                    path: 'user-management',
                    name: 'User Management',
                    component: UserManagement,
                    meta: {
                        pageTitle: 'User Management',
                    },
                },
                {
                    path: 'user-management/:guid',
                    name: 'User Details',
                    component: UserManagementForm,
                    meta: {
                        pageTitle: 'User Management',
                    },
                },
            ],
        },
        {
            path: '/',
            component: Home,
            meta: {
                title: 'R/Vision',
            },
            children: [
                {
                    path: 'login',
                    name: 'login',
                    component: Login,
                },
                {
                    path: 'mfa-activation',
                    name: 'MFA Activation',
                    component: MfaActivation,
                },
                {
                    path: 'mfa-input',
                    name: 'MFA Input',
                    component: MfaAuthentication,
                },
                {
                    path: '*',
                    redirect: {
                        name: 'login',
                    },
                },
            ],
        },
    ],
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (!store.getters['auth/isLoggedIn']) {
            store.dispatch('auth/logout')
                .finally(() => next('/login'));
            return;
        }
        if (to.meta.accountType && (to.meta.accountType !== store.state.user.dashboardType)) {
            store.dispatch('auth/logout')
                .finally(() => next('/login'));
            return;
        }
        next();
    } else if (to.fullPath === '/' && store.getters['auth/isLoggedIn']) {
        next();
    } else {
        // This goes through the matched routes from last to first,
        // finding the closest route with a title.
        // eg. if we have /some::v-deepnested/route and /some, /deep, and /nested
        // have titles, nested's will be chosen.
        const nearestWithTitle = to.matched.slice().reverse().find(
            (r) => r.meta && r.meta.title,
        );

        // Find the nearest route element with meta tags.
        const nearestWithMeta = to.matched.slice().reverse().find(
            (r) => r.meta && r.meta.metaTags,
        );

        // If a route with a title was found, set the document (page) title to that value.
        if (nearestWithTitle) {
            document.title = nearestWithTitle.meta.title;
        }

        // Remove any stale meta tags from the document using the key attribute we set below.
        Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(
            (el) => el.parentNode.removeChild(el),
        );

        // Skip rendering meta tags if there are none.
        if (!nearestWithMeta) {
            next();
            return;
        }

        // Turn the meta tag definitions into actual elements in the head.
        nearestWithMeta.meta.metaTags.map((tagDef) => {
            const tag = document.createElement('meta');

            Object.keys(tagDef).forEach((key) => {
                tag.setAttribute(key, tagDef[key]);
            });

            // We use this to track which meta tags we create, so we don't interfere with other ones.
            tag.setAttribute('data-vue-router-controlled', '');

            return tag;
        })
            // Add the meta tags to the document head.
            .forEach((tag) => document.head.appendChild(tag));

        next();
    }
});

export default router;
