import { isArray, isString } from 'lodash';
import { isNumeric } from '@/utils/is';

export function capitalizeAndRemoveUnderscores(value) {
    if (!value && typeof value !== 'number') {
        return '';
    }

    const val = value.toString();
    return (val.charAt(0).toUpperCase() + val.slice(1)).replace(/_/g, ' ');
}

export function convertApiStringArr(value, excludeNone = true, callback = null) {
    let results;

    if (!value) {
        return '';
    }

    if (isArray(value)) {
        results = value;
    } else if (isNumeric(value)) {
        results = [value];
    } else {
        results = value
            .replace(/\[|\]|'/g, '')
            .split(',')
            .map((i) => i.trim());
    }

    if (excludeNone) {
        results = results.filter((result) => {
            if (isNumeric(result)) {
                return true;
            }

            if (isString(result)) {
                return !result.includes('None');
            }

            return false;
        });
    }

    if (callback) {
        results = results.map(callback);
    }

    return results;
}

export function slugify(text) {
    return text
        .toString()
        .normalize('NFD') // @TODO add polyfill
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-');
}

const VOWELS = 'aeiou'.split('');
const CONSONANTS = 'bcdfghjklmnprstvwxyz'.split('');
const VOWELS_LENGTH = VOWELS.length;
const CONSONANTS_LENGTH = CONSONANTS.length;

export function generateLabel(len = 20) {
    let randomstring = '';
    const salt = Math.floor(Math.random() * 2);
    for (let i = len + salt, end = 0 + salt; i > end; i -= 1) {
        if (i % 2 === 0) {
            randomstring += CONSONANTS[Math.floor(Math.random() * CONSONANTS_LENGTH)];
        } else {
            randomstring += VOWELS[Math.floor(Math.random() * VOWELS_LENGTH)];
        }
    }
    return randomstring;
}
