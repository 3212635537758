<template>
    <div class="totals columns is-multiline">
        <!--
        <div class="totals__col column is-6-tablet is-3-desktop">
            <be-widget icon="blank" iconType="is-critical" :is-loading="isLoading">
                <template v-slot:heading>Elevated Risk <br /> Observations (Speed)</template>
                <template v-slot:content>
                    <p class="label-widget-number">{{ formattedRiskCount.veryHigh }}</p>
                </template>
            </be-widget>
        </div>
        -->
        <div class="totals__col column is-6-tablet is-3-desktop">
            <be-widget icon="blank" iconType="is-danger" :is-loading="isLoading">
                <template v-slot:heading>Elevated Risk Observation</template>
                <template v-slot:content>
                    <p class="label-widget-number">{{ formattedRiskCount.high }}</p>
                </template>
            </be-widget>
        </div>
        <div class="totals__col column is-6-tablet is-3-desktop">
            <be-widget icon="blank" iconType="is-warning" :is-loading="isLoading">
                <template v-slot:heading>Moderate Risk Observations</template>
                <template v-slot:content>
                    <p class="label-widget-number">{{ formattedRiskCount.medium }}</p>
                </template>
            </be-widget>
        </div>
        <div class="totals__col column is-6-tablet is-3-desktop">
            <be-widget icon="blank" iconType="is-success" :is-loading="isLoading">
                <template v-slot:heading> Low Risk Observation</template>
                <template v-slot:content>
                    <p class="label-widget-number">{{ formattedRiskCount.low }}</p>
                </template>
            </be-widget>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    watch: {
        getFilterHash() {
            this.refreshData();
        },
    },

    mounted() {
        this.refreshData();
    },

    computed: {
        ...mapGetters('hs/riskLevel', ['getCountData', 'isLoading']),
        ...mapGetters('dataFilters', ['getFilterHash']),

        riskLevelCount() {
            return this.getCountData;
        },

        formattedRiskCount() {
            return {
                veryHigh: this.riskLevelCount.veryHigh.toLocaleString() || '',
                high: this.riskLevelCount.high.toLocaleString() || '',
                medium: this.riskLevelCount.medium.toLocaleString() || '',
                low: this.riskLevelCount.low.toLocaleString() || '',
            };
        },
    },

    methods: {
        ...mapActions('hs/riskLevel', ['loadData']),

        refreshData() {
            this.loadData();
        },

        formatNumber(number) {
            return number.toLocaleString();
        },
    },
};
</script>

<style lang="scss" scoped>
.totals {
    justify-content: stretch;

    &__col {
        display: flex;
        justify-content: stretch;

        ::v-deep .be-widget__content {
            display: flex;
            align-items: end;
        }
    }
}
</style>
