<template>
    <b-modal
        :active="active"
        :destroy-on-hide="true"
        has-modal-card
        trap-focus
        scroll="keep"
        :width="600"
    >
        <div class="modal-card is-narrower">
            <header class="modal-card-head">
                <div class="modal-card-title">
                    Tags
                </div>
                <button type="button" class="modal-card-close" @click.prevent="close()">
                    <b-icon icon="close" />
                </button>
            </header>
            <div class="modal-card-body">
                <b-field class="tag-search">
                    <b-input
                        v-model="search"
                        placeholder="Search tags"
                        :icon-right="showClearIcon ? 'close-circle' : ''"
                        :icon-right-clickable="showClearIcon"
                        @icon-right-click="clearSearch"
                    />
                </b-field>
                <TransitionGroup name="tag-list" tag="div" class="tags">
                    <b-button
                        rounded
                        v-for="tag in displayTags"
                        :key="`tag-${tag.slug}`"
                        :type="getTagType(tag)"
                        @click="onTagClick(tag)"
                        :class="[
                            'be-button',
                            'is-small',
                            'is-tag',
                            getBorderStyle(tag.type),
                        ]"
                    >
                        {{ tag.label }}
                    </b-button>
                </TransitionGroup>
            </div>
        </div>
        <footer class="modal-card-foot">
            <b-button class="is-outline" @click.prevent="close()">
                Cancel
            </b-button>
            <b-button class="is-primary" @click.prevent="selectTags()">
                Select
            </b-button>
        </footer>
    </b-modal>
</template>

<script>
import { mapState } from 'vuex';
import { findIndex } from 'lodash';

export default {
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        currentTags: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            selectedTags: [],
            displayTags: [],
            search: '',
        };
    },

    computed: {
        ...mapState('tags', ['tags']),

        showClearIcon() {
            return this.search.trim().length > 0;
        },
    },

    watch: {
        active: {
            handler(val) {
                if (!val) {
                    return;
                }
                this.$nextTick(() => {
                    this.selectedTags = [...this.currentTags];
                });
            },
            immediate: true,
        },
        tags: {
            handler(val) {
                this.displayTags = val.filter((tag) => tag.type);
            },
            immediate: true,
        },
        search: {
            handler(val) {
                const searchStr = val.trim().toLowerCase();

                this.displayTags = this.tags.filter((tag) => {
                    if (!tag.type) {
                        return false;
                    }

                    if (searchStr.length === 0) {
                        return true;
                    }

                    return tag.label.toLowerCase().includes(searchStr);
                });
            },
        },
    },

    methods: {
        selectTags() {
            this.$emit('select-tags', this.selectedTags);
            this.selectedTags = [];
            this.close();
        },

        close() {
            this.$emit('update:active', false);
        },

        onTagClick(tag) {
            const index = findIndex(this.selectedTags, (selectedTag) => selectedTag.slug === tag.slug);

            if (index === -1) {
                this.selectedTags.push(tag);
                return;
            }

            this.$delete(this.selectedTags, index);
        },

        getTagType(tag) {
            return this.isTagSelected(tag) ? 'is-primary' : '';
        },

        isTagSelected(tag) {
            return this.selectedTags.map((t) => t.slug).includes(tag.slug);
        },
        getBorderStyle(tagType) {
            if (tagType === 'CAM') {
                return 'border-red';
            } if (tagType === 'SITE') {
                return 'border-green';
            }
            return '';
        },

        clearSearch() {
            this.search = '';
        },
    },
};
</script>

<style lang="scss" scoped>
.modal-card-body {
    .be-button {
        font-weight: 700;
        margin: 0 5px 5px 0;
    }
}

.modal-card-foot {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tags .border-red {
    border: 1px solid $secondary;
}

.tags .border-green {
    border: 1px solid green;
}

.tag-search {
    ::v-deep .input {
        border: none;
        border-radius: 0;
        border-bottom: 1px solid #212732;
    }

    .tag-list-enter-active,
    .tag-list-leave-active {
        transition: all 3s ease-in-out;
    }

    .tag-list-move {
        transition: all 3s ease-in-out;
    }
}
</style>
