import { get, isArray, isEmpty, lowerCase } from 'lodash';
import { convertApiStringArr } from '@/utils/str';

export function transformImageDetectionsObject({ image = {}, row = {} }) {
    let detections = [];
    const imageObj = get(row, 'image', image);

    if (!imageObj || !imageObj.detected_objects) {
        return detections;
    }

    const tags = row?.tags || [];
    if (imageObj.detected_objects.results && imageObj.detected_objects.results.length) {
        detections = detections.concat(
            imageObj.detected_objects.results.map((result) => ({
                is_plate: true,
                type: result.type,
                confidence: result.confidence,
                detection: result.plate,
                tags: [...(row.plate === result.plate ? tags : []), ...(result?.tags || [])],
                orientation: result.vehicle_orientation || null,
                vehicle_model: result.vehicle_makemodel || null,
                vehicle_model_confidence: result.vehicle_makemodel_confidence || null,
            })),
        );
    }

    if (imageObj.detected_objects.results_generic && imageObj.detected_objects.results_generic.length) {
        detections = detections.concat(
            imageObj.detected_objects.results_generic.map((result) => ({
                type: result.object_class,
                confidence: result.confidence,
                objectClass: result.object_class,
                detection: lowerCase(result.object_class || ''),
            })),
        );
    }

    if (imageObj.detected_objects.results_other) {
        Object.keys(imageObj.detected_objects.results_other).forEach((key) => {
            if (isArray(imageObj.detected_objects.results_other[key])) {
                let detection = '';
                switch (key) {
                    case 'people':
                        detection = 'person';
                        break;
                    case 'bicycles':
                        detection = 'bicycle';
                        break;
                    default:
                        detection = '';
                        break;
                }
                imageObj.detected_objects.results_other[key].forEach((row) => {
                    detections.push({
                        type: detection,
                        detection,
                        confidence: row.confidence,
                    });
                });
            }
        });
    }

    return detections;
}

export function detectionsTransformerRow(row) {
    const { image } = row;

    const siteName = get(image, 'camera.site.name', '');
    const cameraName = get(image, 'camera.name', '');
    const tags = get(image, 'camera.tags', []).map((t) => t.slug);

    return {
        ...row,
        siteName,
        cameraName,
        tags,
        detectionTags: row?.tags || [],
        detections: transformImageDetectionsObject({ row }),
        platesList: convertApiStringArr(row?.plate),
        confidences: convertApiStringArr(row?.confidence),
        video: get(image, 'video', null),
    };
}

export function detectionsTransformer(rawData) {
    if (!isArray(rawData) || isEmpty(rawData)) {
        return [];
    }

    return rawData.map((item) => detectionsTransformerRow(item));
}
