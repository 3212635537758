<template>
    <div class="field">
        <div class="control" @click.stop.prevent="copy" @keydown="onKeyDown">
            <div class="copy" :class="{ 'is-expanded': expanded }">
                <div class="copy__text">
                    <span :key="index" v-for="(key, index) in copyText" v-html="`${key}&#8203;`" />
                </div>
                <div v-if="showCopyLabel">
                    <div class="copy__cta">
                        {{ copyLabel }}
                    </div>
                    <input ref="input" type="hidden" :value="copyText" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import noticesMixin from '@/mixins/noticesMixin';

export default {
    mixins: [noticesMixin],

    props: {
        copyText: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            default: 'Copy',
        },
        labelCopied: {
            type: String,
            default: 'Copied!',
        },
        expanded: {
            type: Boolean,
            default: false,
        },
        showCopyLabel: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            copyLabel: this.label,
        };
    },

    beforeDestroy() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    },

    methods: {
        updateLabel() {
            this.copyLabel = this.labelCopied;
            this.timeout = setTimeout(() => {
                this.copyLabel = this.label;
            }, 2000);
        },

        copy() {
            const { input } = this.$refs;
            input.setAttribute('type', 'text');
            input.select();

            try {
                navigator.clipboard.writeText(input.value)
                    .then(() => {
                        this.displaySuccessNotice({ message: 'Copied to clipboard' });
                    })
                    .catch((err) => {
                        this.displayErrorNotice({ message: err.message });
                    });
            } catch (err) {
                this.displayErrorNotice({ message: err.message });
            }

            /* unselect the range */
            input.setAttribute('type', 'hidden');
            window.getSelection().removeAllRanges();

            this.updateLabel();
        },
        onKeyDown(event) {
            if (event.key === 'Enter' || event.key === ' ') {
                this.copy();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    .copy {
        display: flex;
        align-items: stretch;
        cursor: pointer;

        &__text,
        &__cta {
            transition: all $speed $easing;
            padding: .5rem 1rem;
            border: 1px solid $border;
            border-radius: $radius;
            background: $grey-darker;
            @include mobile {
                padding: .5rem .75rem;
            }
        }

        &__text {
            color: $text;
            flex: 1;
            max-width: 500px;
        }

        &.is-expanded & {
            &__text {
                max-width: none;
            }
        }

        &__cta {
            width: 100px;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: $primary;
            font-weight: $weight-bold;
            margin: 0 10px 0 20px;
        }

        &:hover & {
            &__text,
            &__cta {
                border-color: darken($border, 10);
            }
            &__cta {
                color: darken($primary, 10);
            }
        }
    }
</style>
