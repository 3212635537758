<template>
    <span>
        <template v-if="$slots.default">
            <slot />
        </template>
        <template v-else>
            <span v-html="label" />
        </template>
    </span>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters('dataFilters', ['getDateRange', 'getPeriodLabel']),

        startDate() {
            return this.$date(this.getDateRange.start);
        },

        endDate() {
            return this.$date(this.getDateRange.end);
        },

        isEndDateToday() {
            return this.endDate.isToday();
        },

        labelDateFrom() {
            if (this.isEndDateToday && this.startDate.isSame(this.$date(), 'month')) {
                return this.startDate.fromNow();
            }
            return this.startDate.format('MMM, D, YYYY');
        },

        labelDateTo() {
            if (this.isEndDateToday) {
                return 'today';
            }
            return this.endDate.format('MMM, D, YYYY');
        },

        label() {
            if (this.getPeriodLabel) {
                return `Detections for the
                        <span class="has-text-secondary">
                        ${this.getPeriodLabel.toLowerCase()}
                        </span>`;
            }

            return `Detections from
                    <span class="has-text-secondary">
                    <span class="has-nowrap">${this.labelDateFrom}</span>
                    to
                    <span class="has-nowrap">${this.labelDateTo}</span>
                    </span>
                `;
        },
    },
};
</script>
